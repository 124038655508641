.resumeContainer {
  :global {
    .ant-form-item {
      @apply mb-2;
    }
  }
}

.sliceAndImgContainer {
  :global {
    .ant-upload {
      display: block !important;
    }
  }
}

.imgPreview {
  :global {
    img {
      height: 100% !important;
    }
  }
}
